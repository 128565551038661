<template>
  <div>
    <Form
      v-if="!registered"
      v-slot="slotProps"
      ref="form"
      @submit="submitProfessionalRegistration"
      class="Step"
    >
      <step-list
        ref="steps"
        :step="step"
        :steps="steps"
        v-on:triggerPrevStep="this.prevStep()"
      />
        <!-- step 1 -->
        <transition :name="formTransition" mode="out-in">
          <div v-show="isStepOne">
            <strong
              class="Headline is-size-5 d-block"
              v-t="'registration.professional.information'"
            />

            <input-select-component
              :options="userTypes"
              :fieldName="fieldNames.iAm"
              :fieldLabel="iAmFieldLabel"
              @input="$store.commit('user/setUserType', $event.code)"
            />
            <input-component :fieldName="fieldNames.email" :fieldLabel="emailFieldLabel" :rules="`required|email`" type="email" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.firstName" :fieldLabel="firstNameFieldLabel" :rules="`required|max:20`" type="text" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.lastName" :fieldLabel="lastNameFieldLabel" :rules="`required|max:20`" type="text" :errors="slotProps"/>

            <input-select-component
              :options="professions"
              :fieldName="fieldNames.professionName"
              :fieldLabel="professionsFieldLabel"
            />

          </div>
        </transition>
        <!-- step 2 -->
        <transition :name="formTransition" mode="out-in">
          <div v-show="isStepTwo">
            <strong
              class="Headline is-size-5 d-block"
              v-text="$t('registration.professional.clinicInformation')"
            />

            <input-component :fieldName="fieldNames.institutionName" :fieldLabel="institutionFieldLabel" :rules="isStepTwo ? `required|max:100` : ''" type="text" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.street" :fieldLabel="streetFieldLabel" :rules="isStepTwo ? `required|max:100` : ''" type="text" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.city" :fieldLabel="cityFieldLabel" :rules="isStepTwo ? 'required|max:50' : ''" type="text" :errors="slotProps"/>

            <input-select-component
              :options="countries"
              :fieldName="fieldNames.country"
              :fieldLabel="countryFieldLabel"
              :rules="isStepTwo ? 'required' : ''"
            />
          </div>
        </transition>

        <button
          class="Button form-button d-block"
          :class="{ 'is-grey': !slotProps.meta.valid }"
          v-text="$t(nextLabel)"
        />

        <button
          type="button"
          v-if="step > 0"
          class="Button form-button is-link d-block"
          v-text="$t('form.prev')"
          @click="prevStep"
        />
    </Form>
    <p v-if="!isEdit">
      {{ $t('form.alreadyAccount') }} <link-router
      path="home"
      >{{ $t('form.loginNow') }}</link-router>
    </p>

    <regapp-feedback v-if="registered">
      <template #body>
        <div v-html="translateWithMymedelString(`${responseMessageKey}`)" />
      </template>

      <template #footer>

        <br>

        <link-router
          class-names="Link d-i-block text-grey mt-1"
          path="dashboard"
        >
          {{ $t('links.toDashboard.label') }}
        </link-router>
      </template>
    </regapp-feedback>
    <sweet-modal icon="warning" ref="modalError">
      <div v-html="$t('registration.messages.internal:error')" />
    </sweet-modal>
    <validation-error-message :errors="errors" ref="validationErrorMessage" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import registrationSteps from '@/mixins/registrationForm.js'
import ValidationErrorMessage from '../components/ValidationErrorMessage.vue'
import localeMessages from '@/mixins/localeMessages'
import { Form } from 'vee-validate'
import InputComponent from '@/components/partials/InputComponent.vue'
import InputSelectComponent from '@/components/partials/InputSelectComponent.vue'

export default {
  name: 'RegisterFormProfessional',

  props: {
    submitSuccess: {
      type: Boolean,
      default: false
    },

    isEdit: {
      type: Boolean,
      default: false
    }
  },

  mixins: [ registrationSteps, localeMessages ],

  components: {
    ValidationErrorMessage,
    Form,
    InputComponent,
    InputSelectComponent
  },

  computed: {
    ...mapGetters('user', [
      'user',
      'countryString'
    ]),

    professions () {
      return this.getOptionValues('dropdowns.professions', true)
    },

    benefits () {
      return this.$tm('registration.professional.benefits')
    },
    isStepOne () {
      return this.step === 0
    },

    isStepTwo () {
      return this.step === 1
    }
  },

  data () {
    return {
      errors: {},
      step: 0,
      steps: 2,
      country: null,
      confirmations: {
        terms: false
      },
      userRole: null,
      registered: false,
      // fullRegistration: false,
      responseMessageKey: '',
      formTransition: 'form-step-left',
      userTypes: this.getOptionValues('form.userRoles', true), // TODO: define a value in form object in language file
      userType: '',
      fieldNames: {
        iAm: 'iAm',
        email: 'email',
        firstName: 'firstName',
        lastName: 'lastName',
        professionName: 'professionName',
        institutionName: 'institutionName',
        street: 'street',
        city: 'city',
        country: 'country'
      }
    }
  },

  mounted () {
    if (this.isEdit) {
      this.$refs.form.setFieldValue(this.fieldNames.iAm, this.user.userType)
      this.$refs.form.setFieldValue(this.fieldNames.firstName, this.user.firstName)
      this.$refs.form.setFieldValue(this.fieldNames.lastName, this.user.lastName)
      this.$refs.form.setFieldValue(this.fieldNames.email, this.user.email)

      this.country = this.countries.find(i => i.code === this.user.country)
      this.$refs.form.setFieldValue(this.fieldNames.country, this.country)
      this.language = this.$i18n.locale ? this.$i18n.locale : 'en'
      this.userType = this.userTypes.find(x => x.code === 'Professional')
      let tempValues = Object.assign({}, this.$refs.form.getValues())
      this.$refs.form.resetForm({ errors: {}, values: tempValues })
    }
  },

  methods: {
    ...mapActions({
      loadUserProfile: 'user/getUserFromDB',
      loadServiceClaims: 'user/getServiceClaims'
    }),
    prevStep () {
      this.formTransition = 'form-step-right'
      this.step = Math.max(0, this.step - 1)
      this.scrollUp()
    },

    async submitProfessionalRegistration (values) {
      let tempValues = Object.assign({}, this.$refs.form.getValues())
      if (this.step + 1 < this.steps) {
        this.$refs.form.resetForm({ errors: {}, values: tempValues })
        return this.nextStep()
      }

      // since the user might have changed some data from the profile, we also have to patch the user profile
      var patchedProfileSuccessful = await this.patchUserProfile(this.getRequestObject(values))
      if (patchedProfileSuccessful) {
        var postedUpradeSuccessful = await this.postUpgrade(this.getRequestObject(values))
        if (postedUpradeSuccessful) {
          this.responseMessageKey = `registration.messages.upgrade`
          this.registered = true
          this.scrollUp()
          try {
            await this.loadUserProfile()
            await this.loadServiceClaims()
          } catch (err) {
            // add error handling if needed
          }
        }
      }
    },

    getOptionValues (translationKey, asObject = false) {
      const entries = this.$tm(translationKey)

      return Object.keys(entries).map(key => {
        return asObject ? {
          code: key,
          label: entries[key]
        } : entries[key]
      })
    },
    getRequestObject (values) {
        return {
        registrationType: 'professional',
        email: values[this.fieldNames.email]?.trim(),
        firstName: values[this.fieldNames.firstName]?.trim(),
        lastName: values[this.fieldNames.lastName]?.trim(),
        userType: 'Professional',
        language: this.$i18n.locale ? this.$i18n.locale : 'en',
        country: values[this.fieldNames.country].code,
        institutionName: values[this.fieldNames.institutionName]?.trim(),
        institutionStreet: values[this.fieldNames.street]?.trim(),
        institutionCity: values[this.fieldNames.city]?.trim(),
        userRole: values[this.fieldNames.professionName].code
      }
    }
  }
}

</script>
